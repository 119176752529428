import '../css/Speakers.scss';

import arrowup from '../assets/img/arrow-up.png';
import arrowdown from '../assets/img/arrow-down.png';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useState } from 'react';
import { useEffect } from 'react';


export default function Speakers() {

    const [oldHeight, setOldHeight] = useState(null)
    const [toggle, setToggle] = useState(false)

    const speakers = require('../data/Speakers.json');


    const getDevice = () => {
        if (window.innerWidth > 600)
            return 'd'
        else return 'm'
    }

    useEffect(() => {

        if (getDevice() == 'm') {
            window.onload = () => {
                const element = document.querySelector(".panel-container");
                setOldHeight(element?.childNodes[0].clientHeight)
            }
            var element = document.querySelector(".panel-container");

            if (toggle) {
                setOldHeight(element.childNodes[0].clientHeight)
                const childs = element.childElementCount;
                element.style.height = (oldHeight + 12) * Math.round(childs / 2) + "px";
            } else if (oldHeight != null && oldHeight > 200) {
                console.log(element.style.height)
                element.style.height = oldHeight + "px";
            }
        }

    }, [oldHeight, toggle])


    const togglePanel = () => {
        console.log("Test")
        setToggle(!toggle)

        const element2 = document.getElementById("panel-arrow");
        element2.classList.toggle("rotate-arrow");
    };

    return (
        <div id="speakers" className="Speakers flex-top-padding">
            <div className='label'>Speakers</div>
            {/*<div className="speaker-container ">
                {
                    speakers[0].keynote.map((x, i) => {
                        return <div key={i} className='speaker-wrapper'>
                            <article className='intro'>
                                <div className='profileCon'>
                                    <img className='profileImg' src={require(`../assets/img/speakers/${x.src}.jpeg`)} alt={x.alt} />
                                    <a className='iconCon' href={x.linkedin} target='_blank' rel='noreferrer'><LinkedInIcon className='LIcon'/></a>
                                </div>
                                <div className='intro-label' placeholder={x.placeholder}>
                                    <span className="fname">{x.fname + " " + x.lname}</span>
                                    <div className="title">{x.title}</div>
                                </div>
                            </article>
                        </div>
                    })
                }
            </div>*/}
            <div className={toggle ? "speaker-container panel-container open" : "speaker-container panel-container"}>
                {
                    speakers[1].panel.map((x, i) => {
                        return <div key={i} className='speaker-wrapper panel'>
                            <article className='intro'>
                                <div className='profileCon'>
                                    <img className='profileImg' src={require(`../assets/img/speakers/${x.src}.jpeg`)} alt={x.alt} />
                                    <a className='iconCon' href={x.linkedin} target='_blank' rel='noreferrer'><LinkedInIcon className='LIcon'/></a>
                                </div>
                                <div className='intro-label' placeholder={x.placeholder}>
                                    <span className="fname">{x.fname + " " + x.lname}</span>
                                    <div className="title">{x.title}</div>
                                </div>
                            </article>
                        </div>
                    })
                }
            </div>
            <img id="panel-arrow" src={arrowdown} onClick={togglePanel} alt='Arrow down' />
            {/* <button className='seemore' onClick={toggleSpeaker}>See More</button> */}
        </div>
    )
}