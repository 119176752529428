import '../css/Home.scss';
import odiGlobal from '../assets/img/ODI_white.svg';
import curly_arrow from '../assets/img/hero/curly_arrow.png';
import fan from '../assets/img/hero/fan.png';
import city from '../assets/img/hero/city.png';
import useEffect from 'react';



import { useState } from 'react';

export default function Home() {




    const getDevice = () => {
        if (window.innerWidth > 600)
            return 'd'
        else return 'm'
    }

    const handleButton= () => {
         window.open('https://my.cdm.depaul.edu/cim/odiconf/register', '_blank');
    }

    

    return (
        <div id="home" className="Home">
            
            <main className='flex-top-padding'>
                <section className="home-wrapper">
                    <div className='left'>
                        <div className='first-line' id='first-line'>
                            <span className='date'>October 25th, 2024</span>
                            <span className='location'>Chicago</span>
                        </div>
                        <div className='optimizing-text' id='optimizing-text'>
                            Optimizing Digital Innovation:
                        </div>
                        <span className='future-text'>For an Intelligent Future  </span>
                        <div className='fourth-line' id='fourth-line'>
                            <button className='reigster-button' onClick={handleButton}>Register</button>
                            <img src={curly_arrow} alt="curly arrow pointing to button" className='curly-arrow'/>
                        </div>
                    </div>
                    <div className='right'>
                        <div className='fan-wrapper'>
                            <img src={fan} alt="fan" className='fan-image'/>
                        </div>
                        {/* <div className='rectangle'></div> */}
                        <div className='city-wrapper'>
                            <img src={city} alt="city" className='city-image'/>
                        </div>
                        
                      
                    </div>
                </section>
            </main>
        </div>
    )
}