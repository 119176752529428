import React from 'react'
import linkedin from '../assets/img/in-l.png';
import idlab from '../assets/img/idlab_round.svg';
import odi24 from '../assets/img/odi_logo_white.png';
import '../css/ODIFooter.scss'

function ODIFooter() {
  return (
    <footer>
        <div className='f-container'>
            <div className='f-wrapper'>
            <section className='foot f1'>
                <img id="id-lab" src={odi24} alt='Depaul Idlab' />
            </section>
            <section className='foot f2'>
                <section className='f2-1'>
                <span className='label'>Location</span>
                <span className='txt'>1 E. Jackson Blvd. Chicago, IL 60604</span>
                </section>
                <section className='f2-2'>
                <span className='label'>Contact</span>
                <span className='txt'>(312) 362-8000</span>
                <span className='txt'>idlab@depaul.edu</span>
                {/*<span className='txt'>depaulilab@gmail.com</span>*/}
                </section>
                <section className='f2mb'>
                    <section className='f2-11'>
                    <span className='label'>Location</span>
                    </section>
                    <section className='f2-21'>
                    <span className='label'>Contact</span>
                    </section>
                    <section className='f2-12'>
                    <span className='txt'>1 E. Jackson Blvd. Chicago, IL 60604</span>
                    </section>
                    <section className='f2-22'>
                    <span className='txt'>(312) 362-8000</span>
                    <span className='txt'>idlab@depaul.edu</span>
                    {/*<span className='txt'>depaulilab@gmail.com</span>*/}
                    </section>
                </section>
                <section className='f2-3'>
                <span className='label'>Past Conferences</span>
                <div className='prev-conf'>
                    <span>
                    <a className='txt' href='https://odi2023.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2023</a>
                    <a className='txt' href='https://odi2022.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2022</a>
                    <a className='txt' href='https://odi2021.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2021</a>
                    <a className='txt' href='https://odi2020.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2020</a>
                    </span>
                    <span>
                    <a className='txt' href='https://odi2019.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2019</a>
                    <a className='txt' href='https://odi2018.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2018</a>
                    <a className='txt' href='https://odi2017.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2017</a>
                    <a className='txt' href='https://odi2016.depaulidlab.com/' target='_blank' rel={"noreferrer"}>2016</a>
                    </span>
                </div>
                </section>
            </section>
            <section className='foot f3'>
                <span className='label'>Connect with us!</span>
                <div className='social'>
                <a href='https://www.linkedin.com/company/depaul-innovation-lab/' target='_blank' rel={"noreferrer"}><img src={linkedin} alt='Linkedin' /></a>
                <a href='https://depaulidlab.com/' target='_blank' ><img src={idlab} alt='IDLab' /></a>
                </div>
            </section>
            </div>
            <div className='copyright'>©2024 DePaul iD Lab. All Rights Reserved.</div>
        </div>
    </footer>
  )
}

export default ODIFooter
