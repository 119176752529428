import '../css/Schedule.scss';


export default function Schedule() {

    const schedule = require('../data/Schedule.json');

    return (
        <div id="schedule" className="Schedule flex-top-padding">
            <div className='label'>Schedule</div>
            <div className='container'>
                {
                    schedule.sections.map((x, i) => {
                        return <>
                            <div id={i} className={x.isDis ? 'wrapper odd' : 'wrapper'}>
                                <div className='left'>
                                    <span className='time'>{x.time}</span>
                                </div>
                                <div className='right'>
                                    <span className='link'>{x.label}</span>
                                    {x.isDis && x.speakers != null && 
                                    <div className='info-wrap'>
                                        {
                                            x.speakers.map((y, j) => {
                                                return <div id={`${i}.${j + 1}`}>{y}</div>
                                            })
                                        }
                                    </div>}
                                </div>
                            </div>
                        </>
                    })
                }
            </div>
        </div>
    );
}