// Import styling
import '../css/Sponsorship.scss'

// Import image
import abbott_sponsor_logo from '../assets/img/abbott_sponsor_logo_2024.png';
import bosch_sponsor_logo from '../assets/img/bosch_sponsor_logo_2024.png';
import depaul_sponsor_logo from '../assets/img/depaul_logo_sponsor_2024.png';
import kpmg_sponsor_logo from '../assets/img/kpmg_sponsor_logo_2024.png';
import walgreens_sponsor_logo from '../assets/img/walgreens_sponsor_logo.png';
import origin_sponsor_logo from '../assets/img/origin_sponsor_logo.png'
import omron_sponsor_logo from '../assets/img/omron_sponsor_logo.jpg'
import protiviti_sponsor_logo from '../assets/img/protiviti_sponsor_logo.png'

// Imports for components
import * as React from 'react';
import { Box, Grid } from '@mui/material';

export default function Sponsorship() {
    return (
          <div id="sponsors">
            <Box class="sponsor" >
                <Box class="sponsor-header">
                    Sponsors
                </Box>


                {/*Platinum*/}
                    <Box className="sponsor-category">
                        <Box className="sponsor-body">
                            <div className="sponsor-label">Platinum</div>
                        </Box>
                        <Grid container spacing={2} className="grid-container" >
                            <Grid item xs={12} sm={4}>
                            <div class="abbott-logo-container">
                                <a href="https://www.abbott.com/" target="_blank" rel='noreferrer'>
                                    <img class="abbott-logo-image" src={abbott_sponsor_logo} alt="Abbott Logo"></img>
                                </a>
                            </div>
                            </Grid>
                        </Grid>
                    </Box>

                    {/*Gold*/}
                    <Box className="sponsor-category">
                        <Box className="sponsor-body">
                            <div className="sponsor-label">Gold</div>
                        </Box>
                        <Grid container spacing={2} className="grid-container" >
                            <Grid item xs={12} sm={4}>
                            <div class="origin-logo-container">
                                <a href="https://origintech.co.uk/" target="_blank" rel='noreferrer'>
                                    <img class="origin-logo-image" src={origin_sponsor_logo} alt="Origin Logo"></img>
                                </a>
                            </div>
                            </Grid>
                        </Grid>
                    </Box>
                    {/*Silver*/}
                    

                    <Box className="sponsor-category">
                        <Box className="sponsor-body ">
                            <div className="sponsor-label silver">Silver</div>
                        </Box>
                        <Grid container spacing={2} className="grid-container">
                            <Grid item xs={12} sm={4}>
                            <div class="lpmg-logo-container">
                                <a href="https://kpmg.com/xx/en.html" target="_blank" rel='noreferrer'>
                                    <img class="kpmg-logo-image" src={kpmg_sponsor_logo} alt="Kpmg Logo"></img>
                                </a>
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <div class="walgreens-logo-container">
                                <a href="https://www.walgreensbootsalliance.com/" target="_blank" rel='noreferrer'>
                                    <img class="walgreens-logo-image" src={walgreens_sponsor_logo} alt="Walgreens Logo"></img>
                                </a>
                            </div>
                            </Grid>
                        </Grid>
                    </Box>

                    {/*General*/}

                    <Box className="sponsor-category">
                        <Box className="sponsor-body">
                            <div className="sponsor-label">General</div>

                        </Box>
                        <Grid container spacing={2} direction="column" className="grid-container">
                            <Grid item xs={12} sm={4}>
                            <div class="depaul-logo-container">
                                <a href="https://www.depaul.edu/Pages/default.aspx" target="_blank" rel='noreferrer'>
                                    <img class="depaul-logo-image" src={depaul_sponsor_logo} alt="DePaul Logo"></img>
                                </a>
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <div class="omron-logo-container">
                                <a href="https://www.omron.com/global/en/" target="_blank" rel='noreferrer'>
                                    <img class="omron-logo-image" src={omron_sponsor_logo} alt="omron Logo"></img>
                                </a>
                            </div>
                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <div class="protiviti-logo-container">
                                <a href="https://www.protiviti.com/us-en" target="_blank" rel='noreferrer'>
                                    <img class="protiviti-logo-image" src={protiviti_sponsor_logo} alt="protiviti Logo"></img>
                                </a>
                            </div>
                            </Grid>
                            <div class="bosch-logo-container">
                                <a href="https://www.bosch.us/" target="_blank" rel='noreferrer'>
                                    <img class="bosch-logo-image" src={bosch_sponsor_logo} alt="Bosch Logo"></img>
                                </a>
                            </div>
                           
                        </Grid>
                    </Box>
            </Box>
        </div>  
    )
}