// Import styling
import '../css/BecomeSponsor.scss'

// Imports for components
import * as React from 'react';
import { Link } from '@mui/material';

export default function About() {


    return (
        <div class="becomeSponsor">
            <span className='becomeLabel'>Don't miss out! Register today</span>
            <div className='buttonSec'>
                <Link target="_blank" href="https://my.cdm.depaul.edu/CIM/ODIConf/register">
                    <button className='sponsor-register-button'>Register</button>
                </Link>
                <Link href="https://depaulidlab.com/events/contact-us" target="_blank">
                    <button className='become-button'>Become a Sponsor</button>
                </Link>
            </div>
        </div>
    )
}                