import '../css/Highlights.scss';

export default function Highlights() {

    const HighlightsList = require('../data/Highlights.json');


    return (
        <div id="highlights" className="Highlights flex-top-padding">
            {/*<div className='label'>Highlights</div>*/}
            <div className="highlight-container">
                <div className='track'>
                    {HighlightsList[0].forward.map((x, i) => {
                        let checker = HighlightsList[0].forward.length - 5;
                        if(i % checker == 1) {
                            return <div key={i} className='fact part'>
                                <div className='numPlus'>{`${x.num}+`}</div>
                                <div className='forLabel'>{x.label}</div>
                            </div>
                        }
                        else if(i % checker == 3) {
                            return <div key={i} className='fact know'>
                                <div className='numPlus'>{`${x.num}+`}</div>
                                <div className='forLabel'>{x.label}</div>
                            </div>
                        }
                        return <div key={i} className='highlightCon'>
                            <img src={require(`../assets/img/highlights/final/${x.alt}.jpeg`)} alt={x.alt} className="hImg" />
                        </div>
                    })}
                </div>
                <div className='track reverse'>
                    {HighlightsList[1].reverse.map((x, i) => {
                        let checker = HighlightsList[1].reverse.length - 5;
                        if(i % checker == 2) {
                            return <div key={i} className='fact prof'>
                                <div className='numPlus'>{`${x.num}+`}</div>
                                <div className='forLabel'>{x.label}</div>
                            </div>
                        }
                        return <div key={i} className='highlightCon'>
                            <img src={require(`../assets/img/highlights/final/${x.alt}.jpeg`)} alt={x.alt} className="hImg" />
                        </div>
                    })}
                </div>
            </div>
            <div id="mobile-container" className="mobile-container">
            <div id="participating-container-id" className="participating-container">
                    <div className="num">45+</div>
                    <div className="text">Participating</div>
                    <div className="text second-line">Companies</div>
            </div>
            <div id="knowledgable-container-id" className="knowledgable-container">
                    <div className="num">20+</div>
                    <div className="text">Knowledgable</div>
                    <div className="text second-line">Speakers</div>
            </div>
            <div id="professional-container-id" className="professional-container">
                    <div className="num">200+</div>
                    <div className="text">Professional</div>
                    <div className="text second-line">Attendees</div>
            </div>
            </div>
        </div>
        
    )
}