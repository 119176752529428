// Import styling
import '../css/About.scss'

// Import image
import aboutImage from '../assets/img/aboutImage.svg'
import dialogLogo from '../assets/img/dialog_logo.svg'

// Imports for components
import * as React from 'react';
import { Box, Stack, Grid } from '@mui/material';

export default function About() {
    return (
        <div className="about" id="about-id">
            <div className='label'>What is ODi?</div>
            <div className='description-desktop'>
                The Optimizing Digital Innovation Conference is an annual 
                gathering hosted by the DePaul Innovation Development Lab (iD Lab) 
                in Chicago. Each year, nearly 200 industry professionals attend from 
                numerous Fortune 500 companies. This conference features some of the 
                brightest and most talented professionals in the field coming together 
                to share knowledge between industry and academia. ODi is a 
                one-of-a-kind opportunity to network and learn groundbreaking 
                innovation strategies.
            </div>
            <div className='description-mobile'>
            The Optimizing Digital Innovation Conference 
            is an annual gathering hosted by the DePaul 
            Innovation Development Lab (iD Lab) in 
            Chicago. Each year, nearly 200 industry 
            professionals attend from numerous Fortune 
            500 companies. This conference features 
            some of the brightest and most talented 
            professionals in the field coming together to 
            share knowledge between industry and 
            academia. ODi is a one-of-a-kind opportunity 
            to network and learn groundbreaking 
            innovation strategies.
            </div>
        </div>
    )
}