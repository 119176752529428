import { Link } from 'react-scroll';
import './App.scss';
import './css/Header.scss';
import About from './pages/About';
import Highlights from './pages/Highlights';
import Home from './pages/Home';
import Location from './pages/Location';
import Speakers from './pages/Speakers';
import Sponsorship from './pages/Sponsorship';
import BecomeSponsor from './pages/BecomeSponsor';
import ODIFooter from './pages/ODIFooter';
import { useEffect, useState } from 'react';
import arrowup from '../src/assets/img/arrow-up-l.png';

import idlablogo from '../src/assets/img/depaul_idlab_blue.svg';
import fb from '../src/assets/img/fb-icon.png';
import insta from '../src/assets/img/insta-l.png';
import Schedule from './pages/Schedule';
import odi24 from '../src/assets/img/odi_logo.png';
import { Helmet } from 'react-helmet';
import arrowUpImage from '../src/assets/img/arrowUpImage.png';



export default function App() {

  const [showRegisterButton, setShowRegisterButton] = useState(false);
  

  const handleButton= () => {
    window.open('https://my.cdm.depaul.edu/cim/odiconf/register', '_blank');
}


useEffect(() => {
  
  const aboutSection = document.getElementById("about-id");
  const header = document.getElementById("header");

  if (aboutSection && header) {
    const aboutPosition = aboutSection.getBoundingClientRect().top;

    const handleScroll = () => {
     
      
      if (window.scrollY+200 > aboutPosition) {
        header.classList.add("header-bg-white");
        setShowRegisterButton(true);
      } else {
        header.classList.remove("header-bg-white");
        setShowRegisterButton(false);
      }

      
      // if (window.scrollY > 20) {
      //   header.classList.add("head-on-scroll");
      // } else {
      //   header.classList.remove("head-on-scroll");
      // }
    };

   
    window.addEventListener('scroll', handleScroll);

   
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }
}, []); 
  
  




    

  const toggleNav = () => {
    console.log("menu");
    const element = document.querySelector(".nav-menu-sm");
    element.classList.toggle("expand-menu");
  }



  return (
    <div className="App">
      
      <header id='header'>
        <div className='head-wrapper'>

        
        <Link className='idlab-logo' to='home' smooth={true} duration={500}>
          <img id="odi-logo" src={odi24} alt='logo' />
        </Link>
      

          
          <img id='menu-icon' onClick={toggleNav} src={'../assets/img/menu.png'} /> 

         
          <nav id="nav-link-wrap" className='nav-link-wrap'>
         

            
            <Link activeClass="active" className="about-nav scale" to="about" spy={true} smooth={true} duration={500}>
              <span className='scale'>About</span>
            </Link>
            <Link activeClass="active" className="highlights-nav scale" to="sponsors" spy={true} smooth={true} duration={500}>
              <span className='scale'>Sponsors</span>
            </Link>
            <Link activeClass="active" className="sponsors-nav scale" to="speakers" spy={true} smooth={true} duration={500}>
              <span className='scale'>Speakers</span>
            </Link>
            <Link activeClass="active" className="speaker-nav scale" to="schedule" spy={true} smooth={true} duration={500}>
              <span className='scale'>Schedule</span>
            </Link>
            <Link activeClass="active" className="location-nav scale" to="location" spy={true} smooth={true} duration={500}>
              <span className='scale'>Location</span>
            </Link>
            <a href="https://depaulidlab.com/" target="_blank" rel="noopener noreferrer" className="schedule-nav scale">
              <span className='scale'>iD Lab</span>
              <img src={arrowUpImage} alt="Arrow Up" className="arrow-image" />
            </a> 
            
          </nav>
          
        
          
          <div className='register-button-container'>
               <button className={`register-button ${showRegisterButton ? 'show' : ''}`} onClick={handleButton} >
                 Register
              </button>
           </div>
        
        </div>
      </header>
      <div className='nav-menu-sm'>
        <img id='cross-icon' onClick={toggleNav} src={require('./assets/img/cross.png')} />
        <nav id="nav-link-wrap-sm" className='nav-link-wrap-sm'>
         
          <Link activeClass="active" className="about-nav scale" to="about" spy={true} smooth={true} duration={500}>
            <span className='scale'>About</span>
          </Link>
          <Link activeClass="active" className="highlights-nav scale" to="highlights" spy={true} smooth={true} duration={500}>
            <span className='scale'>Sponsors</span>
          </Link>
          <Link activeClass="active" className="sponsors-nav scale" to="sponsors" spy={true} smooth={true} duration={500}>
            <span className='scale'>Speakers</span>
          </Link>
          <Link activeClass="active" className="speaker-nav scale" to="speakers" spy={true} smooth={true} duration={500}>
            <span className='scale'>Schedule</span>
          </Link>
          <Link activeClass="active" className="location-nav scale" to="location" spy={true} smooth={true} duration={500}>
            <span className='scale'>Location</span>
          </Link>
          <Link activeClass="active" className="schedule-nav scale" to="schedule" spy={true} smooth={true} duration={500}>
            <span className='scale'>iD Lab</span>
          </Link>
        </nav>
      </div>
      <main className='main'>
        <Home />
        <About />
        <Highlights />
        <Sponsorship />
        <Speakers />
        <Schedule />
        <Location />
        <BecomeSponsor />
        
      </main>
      <ODIFooter/>
    </div>
  );
};
