// Import styling
import '../css/Location.scss'

// Imports for components
import * as React from 'react';

import location_photo from '../assets/img/location_photo.png';

export default function Location() {
    return (
        
        <div id="location" className="Location">
        <main className='flex-top-padding'>
        <section className="location-wrapper">
            <div className='left'>
                <div className='first-line' id='first-line'>
                    <span className='odi'>ODi 2024 will take place in</span>
                </div>
                <div className='address-text' id='address-text'>
                    <span className='depaul bold'>DePaul Center<br></br></span>
                    <span className='depaul'>1 E Jackson Blvd,<br></br></span>
                     <span className='depaul'>Chicago, IL 60604</span>
                </div>
                <div className='third-line' id='third-line'>
                    <span className='third-line-text-desktop'>A networking reception will take place after the <br></br> 
                    conference's conclusion, on the DePaul Center's <br></br>
                    11th floor. </span>
                    <span className='third-line-text-mobile'>A networking reception will take <br></br>  place after the  
                    conference's <br></br> conclusion, on the DePaul Center's<br></br> 
                    11th floor. </span>
                </div>
            </div>
            <div className='right'>
                
                
                    <img src={location_photo} className='location-photo'/>
                
                
              
            </div>
        </section>
    </main>
   </div>
    )
}